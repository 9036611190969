/**
 * appLinks.js
 *
 * This utility file centralizes all links to the main app (app.resumerevival.xyz)
 * to make it easier to manage routing between the Gatsby site and the React app.
 *
 * Usage:
 * import { getAppLink } from '../util/appLinks';
 *
 * // Then in your component:
 * <Button href={getAppLink('/auth/signin')}>Sign In</Button>
 */

// Base URL for the app
const APP_BASE_URL = "https://app.resumerevival.xyz"

/**
 * Returns a fully qualified URL to the app with the given path
 * @param {string} path - The path to append to the app base URL (should start with a slash)
 * @returns {string} The full URL to the app
 */
export const getAppLink = path => {
  // Ensure path starts with a slash
  const normalizedPath = path.startsWith("/") ? path : `/${path}`
  return `${APP_BASE_URL}${normalizedPath}`
}

/**
 * Common app routes used throughout the site
 */
export const appRoutes = {
  signIn: getAppLink("/auth/signin"),
  signUp: getAppLink("/signup"),
  dashboard: getAppLink("/dashboard"),
  pro: getAppLink("/pro"),
  pricing: getAppLink("/pricing"),
  create: getAppLink("/create"),
  settings: getAppLink("/settings/general"),
}

export default appRoutes
