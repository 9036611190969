// src/util/themeProvider.js

import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react"
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"

// Create a context for theme state management
const ThemeContext = createContext()

// Custom hook to access the theme context
export const useTheme = () => useContext(ThemeContext)

// Main theme provider component
export const ThemeProvider = ({ children }) => {
  // Initialize with a default theme (light) for both server and client
  // This ensures consistent initial rendering
  const [isDark, setIsDark] = useState(false)

  // Check if we're in the browser environment (for SSR compatibility)
  const isBrowser = typeof window !== "undefined"

  // Effect to update theme from localStorage after initial render
  // This runs only on the client side after hydration is complete
  useEffect(() => {
    if (isBrowser) {
      const savedMode = localStorage.getItem("theme-mode")
      if (savedMode === "dark") {
        setIsDark(true)
      }
    }
  }, [isBrowser])

  // Toggle between light and dark mode
  const toggleTheme = () => {
    setIsDark(prev => !prev)
  }

  // Save theme preference to localStorage when it changes and update document
  useEffect(() => {
    if (isBrowser) {
      localStorage.setItem("theme-mode", isDark ? "dark" : "light")

      // Apply theme to document body
      const body = document.body
      if (isDark) {
        body.setAttribute("data-theme", "dark")
        body.style.backgroundColor = "#121212"
        body.style.color = "#ffffff"
      } else {
        body.setAttribute("data-theme", "light")
        body.style.backgroundColor = "#fff"
        body.style.color = "rgba(0, 0, 0, 0.87)"
      }
    }
  }, [isDark, isBrowser])

  // Create the theme based on current mode
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDark ? "dark" : "light",
          primary: {
            main: "#3f51b5", // Indigo - primary brand color
            light: "#7986cb",
            dark: "#303f9f",
          },
          secondary: {
            main: "#FAD9CF", // Soft peach - secondary brand color
            light: "#ffeadf",
            dark: "#d7b8ae",
          },
          background: {
            default: isDark ? "#121212" : "#fff",
            paper: isDark ? "#1e1e1e" : "#fff",
          },
          text: {
            primary: isDark ? "#ffffff" : "rgba(0, 0, 0, 0.87)",
            secondary: isDark
              ? "rgba(255, 255, 255, 0.7)"
              : "rgba(0, 0, 0, 0.6)",
          },
          // Custom palette values for specific components
          custom: {
            glassCard: {
              background: isDark
                ? "rgba(30, 30, 30, 0.85)"
                : "rgba(255, 255, 255, 0.85)", // Increased opacity for better readability
              border: isDark
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "1px solid rgba(63, 81, 181, 0.2)", // More visible border in light mode
              text: isDark ? "#ffffff" : "rgba(0, 0, 0, 0.87)", // Darker text for better contrast in light mode
              shadow: isDark
                ? "0 8px 32px rgba(0, 0, 0, 0.35)"
                : "0 8px 32px rgba(63, 81, 181, 0.15)",
            },
            sections: {
              light: isDark ? "#1e1e1e" : "#f8f9fa",
              medium: isDark ? "#2d2d2d" : "#e9ecef",
              gradient1: isDark
                ? "radial-gradient(ellipse at bottom center, #8B6D65 0%, #3f51b5 50%, #1a237e 100%)"
                : "radial-gradient(ellipse at bottom center, #FAD9CF 0%, #C4CAEF 50%, #3f51b5 100%)",
              gradient2: isDark
                ? "linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)"
                : "linear-gradient(135deg, #f8f0ed 0%, #e8eaf6 100%)",
            },
            accent: {
              peach: "#FAD9CF",
              peachDark: "#d7b8ae",
              indigo: "#3f51b5",
              indigoLight: "#7986cb",
            },
          },
        },
        components: {
          // Card styling from the original static theme
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: isDark ? "#2d2d2d" : "#3f51b5",
                color: "#fff",
                borderRadius: "12px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: isDark
                    ? "0 12px 28px rgba(0, 0, 0, 0.4)"
                    : "0 12px 28px rgba(63, 81, 181, 0.2)",
                },
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                backgroundColor: isDark
                  ? "rgba(30, 30, 30, 0.85)"
                  : "rgba(255, 255, 255, 0.85)", // Increased opacity for better readability
                color: isDark ? "#ffffff" : "rgba(0, 0, 0, 0.87)", // Darker text for better contrast in light mode
                borderRadius: "8px",
                marginBottom: "8px",
                "&:before": {
                  display: "none", // Remove the default divider
                },
                boxShadow: isDark
                  ? "0 4px 12px rgba(0, 0, 0, 0.2)"
                  : "0 4px 12px rgba(63, 81, 181, 0.1)",
              },
            },
          },
          MuiAccordionSummary: {
            styleOverrides: {
              root: {
                "& .MuiSvgIcon-root": {
                  color: isDark ? "#FAD9CF" : "#3f51b5",
                  transition: "transform 0.3s ease",
                },
                "&.Mui-expanded .MuiSvgIcon-root": {
                  transform: "rotate(180deg)",
                },
                padding: "12px 16px",
              },
              content: {
                margin: "8px 0",
              },
            },
          },
          MuiAccordionDetails: {
            styleOverrides: {
              root: {
                padding: "8px 16px 16px",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 600,
                transition: "all 0.3s ease",
                boxShadow: "none",
              },
              containedPrimary: {
                backgroundColor: "#3f51b5",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#303f9f",
                  transform: "translateY(-2px)",
                  boxShadow: "0 6px 12px rgba(63, 81, 181, 0.2)",
                },
              },
              outlinedPrimary: {
                color: isDark ? "#ffffff" : "#3f51b5",
                borderColor: isDark ? "#FAD9CF" : "#3f51b5",
                borderWidth: "2px",
                "&:hover": {
                  backgroundColor: isDark
                    ? "rgba(250, 217, 207, 0.08)"
                    : "rgba(63, 81, 181, 0.08)",
                  borderColor: isDark ? "#d7b8ae" : "#303f9f",
                  transform: "translateY(-2px)",
                },
              },
              textPrimary: {
                color: isDark ? "#FAD9CF" : "#3f51b5",
                "&:hover": {
                  backgroundColor: isDark
                    ? "rgba(250, 217, 207, 0.08)"
                    : "rgba(63, 81, 181, 0.08)",
                },
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: "inherit",
                transition: "transform 0.3s ease, background-color 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  backgroundColor: isDark
                    ? "rgba(255, 255, 255, 0.08)"
                    : "rgba(63, 81, 181, 0.08)",
                },
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundImage: "none",
                borderRadius: "12px",
              },
              elevation1: {
                boxShadow: isDark
                  ? "0 4px 20px rgba(0, 0, 0, 0.2)"
                  : "0 4px 20px rgba(0, 0, 0, 0.08)",
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              colorDefault: {
                backgroundColor: isDark
                  ? "rgba(30, 30, 30, 0.9)"
                  : "rgba(255, 255, 255, 0.9)",
                color: isDark ? "#ffffff" : "inherit",
                backdropFilter: "blur(10px)",
                boxShadow: isDark
                  ? "0 4px 20px rgba(0, 0, 0, 0.2)"
                  : "0 4px 20px rgba(0, 0, 0, 0.05)",
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                borderRadius: "8px",
                fontWeight: 500,
              },
              colorPrimary: {
                backgroundColor: isDark
                  ? "rgba(63, 81, 181, 0.2)"
                  : "rgba(63, 81, 181, 0.1)",
                color: isDark ? "#90caf9" : "#3f51b5",
                border: isDark ? "1px solid rgba(63, 81, 181, 0.3)" : "none",
              },
            },
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderColor: isDark
                  ? "rgba(255, 255, 255, 0.12)"
                  : "rgba(0, 0, 0, 0.08)",
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: isDark ? "#424242" : "#616161",
                borderRadius: "6px",
                fontSize: "0.75rem",
                padding: "8px 12px",
              },
            },
          },
        },
        typography: {
          fontFamily: [
            "Inter",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
          ].join(","),
          h1: {
            fontWeight: 700,
            letterSpacing: "-0.01em",
          },
          h2: {
            fontWeight: 700,
            letterSpacing: "-0.01em",
          },
          h3: {
            fontWeight: 600,
          },
          h4: {
            fontWeight: 600,
          },
          h5: {
            fontWeight: 500,
          },
          h6: {
            fontWeight: 500,
          },
          subtitle1: {
            fontWeight: 500,
          },
          button: {
            fontWeight: 600,
            letterSpacing: "0.02em",
          },
        },
      }),
    [isDark]
  )

  // Provide both the theme and the toggle function to children
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
