/**
 * Google Analytics Utility Functions
 *
 * This file provides helper functions for tracking events and page views
 * with Google Analytics 4 (GA4) in the Resume Revival Gatsby site.
 *
 * Enhanced with comprehensive tracking capabilities for detailed user behavior analysis.
 */

import ReactGA from "react-ga4"

/**
 * Initialize Google Analytics
 * This is called in gatsby-browser.js
 *
 * @param {string} measurementId - The GA4 measurement ID
 * @param {Object} [options] - Optional configuration options
 * @param {boolean} [options.debug] - Enable debug mode
 * @param {Object} [options.customDimensions] - Custom dimensions to set globally
 */
export const initGA = (measurementId, options = {}) => {
  if (typeof window !== "undefined" && !window.GA_INITIALIZED) {
    // Initialize with configuration options
    ReactGA.initialize(measurementId, {
      debug: options.debug || false,
      gaOptions: {
        // Set any custom dimensions that should be applied to all events
        ...(options.customDimensions && {
          customDimensions: options.customDimensions,
        }),
      },
    })

    window.GA_INITIALIZED = true
    console.log("Google Analytics initialized")

    // Set default custom dimensions if provided
    if (options.customDimensions) {
      ReactGA.gtag("set", options.customDimensions)
    }
  }
}

/**
 * Track a page view with enhanced metadata
 *
 * @param {string} path - The page path to track
 * @param {string} [title] - Optional page title
 * @param {Object} [metadata] - Additional metadata for the page view
 */
export const trackPageView = (path, title, metadata = {}) => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    ReactGA.send({
      hitType: "pageview",
      page: path,
      ...(title && { title }),
      ...metadata,
    })

    // Track page load timing if available
    if (window.performance && window.performance.timing) {
      const loadTime =
        window.performance.timing.domContentLoadedEventEnd -
        window.performance.timing.navigationStart

      if (loadTime > 0) {
        trackPerformance("page_load", loadTime, path)
      }
    }
  }
}

/**
 * Track a custom event with enhanced parameters
 *
 * @param {string} category - Event category
 * @param {string} action - Event action
 * @param {string} [label] - Optional event label
 * @param {number} [value] - Optional event value
 * @param {Object} [customDimensions] - Optional custom dimensions for this event
 */
export const trackEvent = (
  category,
  action,
  label,
  value,
  customDimensions = {}
) => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    ReactGA.event({
      category,
      action,
      ...(label && { label }),
      ...(value && { value }),
      ...customDimensions,
    })
  }
}

/**
 * Track a user interaction with a specific feature
 *
 * @param {string} featureName - Name of the feature
 * @param {string} action - The action taken
 * @param {string} [additionalInfo] - Optional additional information
 * @param {Object} [customDimensions] - Optional custom dimensions
 */
export const trackFeatureUse = (
  featureName,
  action,
  additionalInfo,
  customDimensions = {}
) => {
  trackEvent(
    "Feature Interaction",
    action,
    `${featureName}${additionalInfo ? ` - ${additionalInfo}` : ""}`,
    null,
    customDimensions
  )
}

/**
 * Track a form submission with enhanced error tracking
 *
 * @param {string} formName - Name of the form
 * @param {boolean} success - Whether the submission was successful
 * @param {string} [errorMessage] - Optional error message if submission failed
 * @param {Object} [formData] - Optional anonymized form data for analysis
 */
export const trackFormSubmission = (
  formName,
  success,
  errorMessage,
  formData = {}
) => {
  // Remove any sensitive data from formData
  const safeFormData = { ...formData }

  // Remove potentially sensitive fields
  delete safeFormData.email
  delete safeFormData.password
  delete safeFormData.name
  delete safeFormData.phone

  trackEvent(
    "Form",
    success ? "Submission Success" : "Submission Failure",
    `${formName}${!success && errorMessage ? ` - Error: ${errorMessage}` : ""}`,
    null,
    {
      form_name: formName,
      form_success: success ? "true" : "false",
      ...(Object.keys(safeFormData).length > 0 && {
        form_data: JSON.stringify(safeFormData),
      }),
    }
  )
}

/**
 * Track form field interactions
 *
 * @param {string} formName - Name of the form
 * @param {string} fieldName - Name of the field
 * @param {string} action - The action (focus, blur, change, error)
 * @param {string} [errorMessage] - Optional error message if action is 'error'
 */
export const trackFormFieldInteraction = (
  formName,
  fieldName,
  action,
  errorMessage
) => {
  trackEvent(
    "Form Interaction",
    action,
    `${formName} - ${fieldName}${
      errorMessage ? ` - Error: ${errorMessage}` : ""
    }`,
    null,
    {
      form_name: formName,
      field_name: fieldName,
    }
  )
}

/**
 * Track a click on an external link
 *
 * @param {string} url - The URL that was clicked
 * @param {string} [linkText] - Optional text of the link
 * @param {string} [sourcePage] - Optional source page where the link was clicked
 */
export const trackExternalLink = (url, linkText, sourcePage) => {
  trackEvent(
    "Outbound Link",
    "Click",
    linkText ? `${linkText} (${url})` : url,
    null,
    {
      link_url: url,
      link_text: linkText || "",
      source_page:
        sourcePage ||
        (typeof window !== "undefined" ? window.location.pathname : ""),
    }
  )
}

/**
 * Track a click on an internal link for navigation analysis
 *
 * @param {string} destination - The destination path
 * @param {string} [linkText] - Optional text of the link
 * @param {string} [linkType] - Optional type of link (menu, footer, inline, etc.)
 */
export const trackInternalLink = (
  destination,
  linkText,
  linkType = "inline"
) => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    const sourcePath = window.location.pathname

    trackEvent(
      "Navigation",
      "Internal Link Click",
      `${sourcePath} → ${destination}`,
      null,
      {
        link_text: linkText || "",
        link_type: linkType,
        source_path: sourcePath,
        destination_path: destination,
      }
    )
  }
}

/**
 * Track a download
 *
 * @param {string} fileType - Type of file downloaded (PDF, DOCX, etc.)
 * @param {string} fileName - Name of the file
 * @param {string} [fileSize] - Optional size of the file
 * @param {string} [category] - Optional category of the file
 */
export const trackDownload = (fileType, fileName, fileSize, category) => {
  trackEvent("Download", fileType.toUpperCase(), fileName, null, {
    file_type: fileType.toLowerCase(),
    file_name: fileName,
    file_size: fileSize || "",
    file_category: category || "",
  })
}

/**
 * Track a search query
 *
 * @param {string} query - The search query
 * @param {number} [resultCount] - Optional number of results
 * @param {string} [category] - Optional search category
 * @param {Array} [filters] - Optional search filters applied
 */
export const trackSearch = (query, resultCount, category, filters = []) => {
  trackEvent("Search", "Query", query, resultCount, {
    search_term: query,
    search_results: resultCount || 0,
    search_category: category || "general",
    search_filters: filters.length > 0 ? filters.join(",") : "none",
  })
}

/**
 * Track a blog post view with enhanced metrics
 *
 * @param {string} postTitle - Title of the blog post
 * @param {string} postSlug - Slug of the blog post
 * @param {string} [category] - Optional category of the post
 * @param {string} [author] - Optional author of the post
 * @param {string} [publishDate] - Optional publish date
 */
export const trackBlogPostView = (
  postTitle,
  postSlug,
  category,
  author,
  publishDate
) => {
  trackEvent("Blog", "Post View", `${postTitle} (${postSlug})`, null, {
    post_title: postTitle,
    post_slug: postSlug,
    post_category: category || "",
    post_author: author || "",
    post_date: publishDate || "",
  })
}

/**
 * Track a social share
 *
 * @param {string} platform - Social media platform
 * @param {string} contentType - Type of content shared
 * @param {string} contentId - ID or slug of the content
 * @param {string} [contentTitle] - Optional title of the content
 */
export const trackSocialShare = (
  platform,
  contentType,
  contentId,
  contentTitle
) => {
  trackEvent(
    "Social",
    "Share",
    `${platform} - ${contentType} - ${contentId}`,
    null,
    {
      share_platform: platform,
      content_type: contentType,
      content_id: contentId,
      content_title: contentTitle || "",
    }
  )
}

/**
 * Track scroll depth on a page
 *
 * @param {number} depth - Scroll depth percentage (25, 50, 75, 100)
 * @param {string} [pageTitle] - Optional page title
 */
export const trackScrollDepth = (depth, pageTitle) => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    const path = window.location.pathname

    trackEvent(
      "User Engagement",
      "Scroll Depth",
      `${depth}% - ${pageTitle || path}`,
      depth,
      {
        scroll_depth: depth,
        page_path: path,
        page_title: pageTitle || document.title,
      }
    )
  }
}

/**
 * Track time spent on page when user leaves
 *
 * @param {number} seconds - Time spent in seconds
 * @param {string} [pageTitle] - Optional page title
 */
export const trackTimeOnPage = (seconds, pageTitle) => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    const path = window.location.pathname

    trackEvent(
      "User Engagement",
      "Time on Page",
      `${pageTitle || path}`,
      seconds,
      {
        time_seconds: seconds,
        page_path: path,
        page_title: pageTitle || document.title,
      }
    )
  }
}

/**
 * Track user authentication events
 *
 * @param {string} action - The action (sign_in, sign_out, sign_up, etc.)
 * @param {string} method - Authentication method (email, google, facebook, etc.)
 * @param {boolean} success - Whether the authentication was successful
 * @param {string} [errorMessage] - Optional error message if unsuccessful
 */
export const trackAuthentication = (action, method, success, errorMessage) => {
  trackEvent(
    "Authentication",
    action,
    `${method} - ${success ? "Success" : "Failure"}`,
    null,
    {
      auth_action: action,
      auth_method: method,
      auth_success: success ? "true" : "false",
      auth_error: errorMessage || "",
    }
  )
}

/**
 * Track errors that occur on the site
 *
 * @param {string} errorType - Type of error (js, api, validation, etc.)
 * @param {string} errorMessage - Error message
 * @param {string} [errorSource] - Source of the error (file, component, etc.)
 * @param {Object} [errorData] - Additional error data
 */
export const trackError = (
  errorType,
  errorMessage,
  errorSource,
  errorData = {}
) => {
  // Remove any sensitive data
  const safeErrorData = { ...errorData }
  delete safeErrorData.email
  delete safeErrorData.password
  delete safeErrorData.token

  trackEvent(
    "Error",
    errorType,
    `${errorSource ? `${errorSource} - ` : ""}${errorMessage}`,
    null,
    {
      error_type: errorType,
      error_message: errorMessage,
      error_source: errorSource || "",
      error_data:
        Object.keys(safeErrorData).length > 0
          ? JSON.stringify(safeErrorData)
          : "",
      page_url: typeof window !== "undefined" ? window.location.href : "",
    }
  )
}

/**
 * Track performance metrics
 *
 * @param {string} metricName - Name of the metric (page_load, api_call, etc.)
 * @param {number} duration - Duration in milliseconds
 * @param {string} [resource] - Optional resource being measured
 */
export const trackPerformance = (metricName, duration, resource) => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    trackEvent(
      "Performance",
      metricName,
      resource || window.location.pathname,
      Math.round(duration),
      {
        metric_name: metricName,
        duration_ms: Math.round(duration),
        resource: resource || window.location.pathname,
      }
    )
  }
}

/**
 * Track video interactions
 *
 * @param {string} videoId - ID of the video
 * @param {string} videoTitle - Title of the video
 * @param {string} action - Action (play, pause, complete, progress)
 * @param {number} [progress] - Optional progress percentage for progress action
 */
export const trackVideoInteraction = (
  videoId,
  videoTitle,
  action,
  progress
) => {
  trackEvent("Video", action, `${videoTitle} (${videoId})`, progress, {
    video_id: videoId,
    video_title: videoTitle,
    video_action: action,
    video_progress: progress || 0,
  })
}

/**
 * Track ecommerce product interactions
 *
 * @param {string} action - Action (view, click, add_to_cart, purchase)
 * @param {Object} product - Product data
 * @param {string} product.id - Product ID
 * @param {string} product.name - Product name
 * @param {number} product.price - Product price
 * @param {string} [product.category] - Optional product category
 * @param {string} [product.variant] - Optional product variant
 * @param {number} [product.quantity] - Optional product quantity
 */
export const trackEcommerceAction = (action, product) => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    // Use GA4 ecommerce tracking
    switch (action) {
      case "view_item":
        ReactGA.gtag("event", "view_item", {
          currency: "USD",
          value: product.price,
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              price: product.price,
              item_category: product.category || "",
              item_variant: product.variant || "",
            },
          ],
        })
        break

      case "add_to_cart":
        ReactGA.gtag("event", "add_to_cart", {
          currency: "USD",
          value: product.price * (product.quantity || 1),
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              price: product.price,
              item_category: product.category || "",
              item_variant: product.variant || "",
              quantity: product.quantity || 1,
            },
          ],
        })
        break

      case "purchase":
        ReactGA.gtag("event", "purchase", {
          transaction_id: product.transactionId || `T-${Date.now()}`,
          currency: "USD",
          value: product.price * (product.quantity || 1),
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              price: product.price,
              item_category: product.category || "",
              item_variant: product.variant || "",
              quantity: product.quantity || 1,
            },
          ],
        })
        break

      default:
        // For other actions, use standard event tracking
        trackEvent("Ecommerce", action, product.name, product.price, {
          product_id: product.id,
          product_name: product.name,
          product_price: product.price,
          product_category: product.category || "",
          product_variant: product.variant || "",
          product_quantity: product.quantity || 1,
        })
    }
  }
}

/**
 * Set user properties for better segmentation
 *
 * @param {Object} properties - User properties to set
 */
export const setUserProperties = properties => {
  if (typeof window !== "undefined" && window.GA_INITIALIZED) {
    // Remove any PII
    const safeProperties = { ...properties }
    delete safeProperties.email
    delete safeProperties.name
    delete safeProperties.phone

    ReactGA.gtag("set", "user_properties", safeProperties)
  }
}

export default {
  initGA,
  trackPageView,
  trackEvent,
  trackFeatureUse,
  trackFormSubmission,
  trackFormFieldInteraction,
  trackExternalLink,
  trackInternalLink,
  trackDownload,
  trackSearch,
  trackBlogPostView,
  trackSocialShare,
  trackScrollDepth,
  trackTimeOnPage,
  trackAuthentication,
  trackError,
  trackPerformance,
  trackVideoInteraction,
  trackEcommerceAction,
  setUserProperties,
}
