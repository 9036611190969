// src/components/layout.jsx

import React, { useState, useEffect, useRef } from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { makeStyles } from "@mui/styles"
import CookieConsent from "react-cookie-consent"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { Link } from "gatsby"
import { useTheme } from "../util/themeProvider"
import { useTheme as useMuiTheme } from "@mui/material/styles"

const useStyles = makeStyles(() => ({
  appContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  scrollTopButton: {
    position: "fixed",
    bottom: "30px",
    right: "30px",
    width: 70,
    height: 70,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(10px)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "all 0.3s ease-in-out",
    zIndex: 1500,
    pointerEvents: "none",
    "&.visible": {
      opacity: 1,
      pointerEvents: "auto",
    },
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 10px 40px rgba(0, 0, 0, 0.3)",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  },
  buttonIcon: {
    fontSize: "2.5rem",
  },
  cookieConsent: {
    position: "fixed",
    bottom: "20px",
    left: 0,
    right: 0,
    margin: "0 auto",
    width: "calc(100% - 40px)",
    maxWidth: "1200px",
    fontSize: "0.875rem",
    padding: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: 0,
    animation: "$fadeIn 0.5s forwards",
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
    zIndex: 1000,
    borderRadius: "12px",
    overflow: "hidden",
    backdropFilter: "blur(10px)",
    "@media (max-width: 600px)": {
      width: "calc(100% - 20px)",
      bottom: "10px",
    },
  },
  hide: {
    animation: "$fadeOut 0.5s forwards",
  },
  cookieInner: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  cookieText: {
    margin: 0,
    padding: 0,
    textAlign: "left",
    lineHeight: 1.6,
    flex: 1,
    "@media (max-width: 600px)": {
      textAlign: "center",
      marginBottom: "16px",
    },
  },
  cookieButtons: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    "@media (max-width: 600px)": {
      width: "100%",
      justifyContent: "center",
    },
  },
  cookieButton: {
    border: "none",
    borderRadius: "8px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: 600,
    transition: "all 0.3s ease",
    fontFamily:
      "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
    to: {
      opacity: 0.95,
      transform: "translateY(0)",
    },
  },
  "@keyframes fadeOut": {
    from: {
      opacity: 0.95,
      transform: "translateY(0)",
    },
    to: {
      opacity: 0,
      transform: "translateY(20px)",
    },
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles()
  const { isDark } = useTheme()
  const muiTheme = useMuiTheme()
  const [showScrollTopButton, setShowScrollTopButton] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [isHiding, setIsHiding] = useState(false)
  const mainRef = useRef(null)
  const [belowFoldElements, setBelowFoldElements] = useState([])

  // Show/hide Back-to-Top button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTopButton(window.pageYOffset > 100)
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  // Add below-fold class to elements that are below the fold
  useEffect(() => {
    if (typeof window !== "undefined" && mainRef.current) {
      // Calculate the fold position (viewport height)
      const foldPosition = window.innerHeight

      // Find all direct children of the main element
      const childElements = mainRef.current.children

      // Determine which elements are below the fold
      const belowFold = []
      let cumulativeHeight = 0

      Array.from(childElements).forEach((element, index) => {
        const rect = element.getBoundingClientRect()
        cumulativeHeight += rect.height

        // If the element is completely below the fold
        if (cumulativeHeight > foldPosition + 200) {
          // 200px buffer
          belowFold.push(index)
          element.classList.add("below-fold")
        }
      })

      setBelowFoldElements(belowFold)
    }
  }, [])

  // Scroll smoothly to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  // Handle cookie consent animations
  const handleAccept = () => {
    setIsHiding(true)
    setTimeout(() => {
      setIsVisible(false)
    }, 500)
  }

  const handleDecline = () => {
    setIsHiding(true)
    setTimeout(() => {
      setIsVisible(false)
    }, 500)
  }

  // Theme-dependent styles for cookie consent
  const cookieConsentStyle = {
    boxShadow: isDark
      ? "0 8px 32px rgba(0, 0, 0, 0.35)"
      : "0 8px 32px rgba(63, 81, 181, 0.15)",
    backgroundColor: isDark
      ? "rgba(30, 30, 30, 0.85)"
      : "rgba(255, 255, 255, 0.85)",
    border: isDark
      ? "1px solid rgba(255, 255, 255, 0.12)"
      : "1px solid rgba(63, 81, 181, 0.2)",
  }

  // Theme-dependent styles for cookie text links
  const cookieTextLinkStyle = {
    color: muiTheme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 500,
  }

  // Theme-dependent styles for accept button
  const acceptButtonStyle = {
    backgroundColor: muiTheme.palette.primary.main,
    color: "#ffffff",
  }

  // Theme-dependent styles for decline button
  const declineButtonStyle = {
    backgroundColor: "transparent",
    color: isDark ? "#ffffff" : muiTheme.palette.primary.main,
    border: `2px solid ${isDark ? "#FAD9CF" : muiTheme.palette.primary.main}`,
  }

  return (
    <div className={classes.appContainer}>
      {/* Optional TOC styling */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .table-of-contents ul ul ul {
              display: none !important;
            }
          `,
        }}
      />

      {/* Navbar (update your Navbar component with a subtle dropshadow if desired) */}
      <Navbar />

      {/* Main Content */}
      <div style={{ paddingTop: "64px", flex: 1 }}>
        <main ref={mainRef}>{children}</main>
      </div>

      {/* Footer */}
      <Footer />

      {/* Back to Top Button */}
      <button
        onClick={scrollToTop}
        className={`${classes.scrollTopButton} ${
          showScrollTopButton ? "visible" : ""
        }`}
        aria-label="Back to Top"
      >
        <ArrowUpwardIcon className={classes.buttonIcon} />
      </button>

      {/* Cookie Consent Banner */}
      {isVisible && (
        <CookieConsent
          location="bottom"
          buttonText="I Understand"
          cookieName="myAppCookieConsent"
          enableDeclineButton
          declineButtonText="Decline"
          onAccept={handleAccept}
          onDecline={handleDecline}
          containerClasses={`${classes.cookieConsent} ${
            isHiding ? classes.hide : ""
          }`}
          buttonClasses={`${classes.cookieButton} accept`}
          declineButtonClasses={`${classes.cookieButton} decline`}
          buttonWrapperClasses={classes.cookieButtons}
          contentClasses={classes.cookieInner}
          disableStyles
          style={cookieConsentStyle}
          buttonStyle={acceptButtonStyle}
          declineButtonStyle={declineButtonStyle}
        >
          <span className={classes.cookieText}>
            We use cookies to enhance your experience, personalize content, and
            analyze our traffic. By clicking "I Understand", you agree to our
            use of cookies. Read our{" "}
            <Link to="/privacy-policy" style={cookieTextLinkStyle}>
              Privacy Policy
            </Link>{" "}
            for more information.
          </span>
        </CookieConsent>
      )}
    </div>
  )
}

export default Layout
