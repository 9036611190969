// src/components/Navbar.js

import React, { useState, useEffect } from "react"
import {
  AppBar,
  Container,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Divider,
  Box,
  IconButton,
  useScrollTrigger,
  Slide,
  Fade,
  Stack,
  useTheme as useMuiTheme,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import { makeStyles } from "@mui/styles"
import { Link } from "gatsby"
import { useAuth } from "../util/auth"
import { useTheme } from "../util/themeProvider"
import { appRoutes } from "../util/appLinks"
import { trackInternalLink, trackEvent } from "../util/analytics"
import useMediaQuery from "@mui/material/useMediaQuery"
import ThemeToggle from "./ThemeToggle"
import { motion } from "framer-motion"
import { buttonHoverEffect } from "../styles/animations"

// Custom navbar visibility control that shows when scrolling up
function HideOnScroll(props) {
  const { children } = props
  const [visible, setVisible] = useState(true)
  const lastScrollY = React.useRef(0)
  const lastDirection = React.useRef("none")
  const ticking = React.useRef(false)

  useEffect(() => {
    const controlNavbar = () => {
      const currentScrollY = window.scrollY

      // Determine scroll direction
      if (currentScrollY < lastScrollY.current) {
        // Scrolling UP - always show navbar
        lastDirection.current = "up"
        setVisible(true)
      } else if (currentScrollY > lastScrollY.current && currentScrollY > 80) {
        // Scrolling DOWN and not at the top - hide navbar
        lastDirection.current = "down"
        setVisible(false)
      }

      lastScrollY.current = currentScrollY
      ticking.current = false
    }

    const onScroll = () => {
      if (!ticking.current) {
        // Use requestAnimationFrame to limit the number of DOM updates
        window.requestAnimationFrame(controlNavbar)
        ticking.current = true
      }
    }

    // Add scroll event listener
    window.addEventListener("scroll", onScroll)

    // Clean up
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <Slide appear={false} direction="down" in={visible}>
      {children}
    </Slide>
  )
}

const useStyles = makeStyles(theme => ({
  logo: {
    height: 32,
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  spacer: {
    flexGrow: 1,
  },
  navButton: {
    margin: "0 8px",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "1rem",
  },
  mobileMenu: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    padding: "80px 24px 24px",
    backdropFilter: "blur(10px)",
  },
}))

function Navbar() {
  const classes = useStyles()
  const auth = useAuth()
  const { isDark } = useTheme()
  const muiTheme = useMuiTheme()
  const [menuState, setMenuState] = useState(null)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  // For responsive breakpoints
  const isMobile = useMediaQuery("(max-width:900px)")

  // Brand logo
  const logo =
    "https://firebasestorage.googleapis.com/v0/b/resumerevival-bb193.appspot.com/o/logo.png?alt=media&token=9086b528-7c1d-43d0-98b5-1dffa979b644"

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      if (offset > 50) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleOpenMenu = (event, id) => {
    setMenuState({ anchor: event.currentTarget, id })
  }

  const handleCloseMenu = () => {
    setMenuState(null)
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const navLinks = [
    { title: "Features", path: "/features" },
    { title: "Pricing", path: appRoutes.pricing },
    { title: "Blog", path: "/blog" },
    { title: "About", path: "/about" },
  ]

  // Get current colors based on theme
  const textColor = muiTheme.palette.text.primary
  const accentColor = isDark
    ? muiTheme.palette.secondary.main
    : muiTheme.palette.primary.main

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="fixed"
          color="default"
          elevation={0}
          sx={{
            py: scrolled ? 0.5 : 1,
            transition: "all 0.3s ease",
            boxShadow: scrolled
              ? isDark
                ? "0 4px 20px rgba(0, 0, 0, 0.2)"
                : "0 4px 20px rgba(0, 0, 0, 0.05)"
              : "none",
            color: textColor,
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
              {/* Brand Logo - The visually-hidden span provides text for SEO crawlers */}
              <Link to="/" aria-label="Resume Revival Home">
                <motion.img
                  src={logo}
                  alt="Resume Revival Logo"
                  className={classes.logo}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                  width={28}
                  height={32}
                />
                <span className="visually-hidden">Resume Revival Home</span>
              </Link>

              {/* Desktop Navigation */}
              {!isMobile && (
                <Stack direction="row" spacing={1} alignItems="center">
                  {navLinks.map((link, index) => (
                    <motion.div key={index} {...buttonHoverEffect}>
                      <Button
                        component={Link}
                        to={link.path}
                        color="inherit"
                        className={classes.navButton}
                        title={`${link.title} - Resume Revival`}
                        aria-label={`${link.title} - Resume Revival`}
                        onClick={() =>
                          trackInternalLink(link.path, link.title, "main_nav")
                        }
                        sx={{
                          position: "relative",
                          color: textColor,
                          "&::after": {
                            content: '""',
                            position: "absolute",
                            bottom: "6px",
                            left: "50%",
                            width: "0%",
                            height: "2px",
                            backgroundColor: accentColor,
                            transition: "all 0.3s ease",
                            transform: "translateX(-50%)",
                          },
                          "&:hover::after": {
                            width: "60%",
                          },
                        }}
                      >
                        {link.title}
                      </Button>
                    </motion.div>
                  ))}

                  <Box sx={{ mx: 1 }}>
                    <ThemeToggle />
                  </Box>

                  {/* Account Menu */}
                  {auth?.user ? (
                    <motion.div {...buttonHoverEffect}>
                      <Button
                        color="inherit"
                        aria-label="Account"
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        onClick={e => handleOpenMenu(e, "account-menu")}
                        endIcon={<ExpandMoreIcon />}
                        sx={{
                          ml: 1,
                          borderRadius: "8px",
                          px: 2,
                          py: 0.75,
                          color: textColor,
                          backgroundColor: isDark
                            ? "rgba(255, 255, 255, 0.05)"
                            : "rgba(63, 81, 181, 0.05)",
                        }}
                      >
                        Account
                      </Button>
                    </motion.div>
                  ) : (
                    <>
                      {/* Secondary Sign In Button (Desktop) */}
                      <motion.div {...buttonHoverEffect}>
                        <Button
                          href={appRoutes.signIn}
                          variant="text" // Changed from contained
                          color="inherit" // Changed from primary
                          sx={{
                            ml: 1,
                            borderRadius: "8px",
                            px: 2, // Adjusted padding
                            py: 0.75,
                            textTransform: "none",
                            fontWeight: 500, // Adjusted weight
                            color: textColor, // Ensure correct text color
                          }}
                        >
                          Sign in
                        </Button>
                      </motion.div>
                      {/* Primary Sign Up Button (Desktop) */}
                      <motion.div {...buttonHoverEffect}>
                        <Button
                          href={appRoutes.signIn} // Reverted back to signIn
                          variant="contained"
                          color="primary"
                          sx={{
                            ml: 1,
                            borderRadius: "8px",
                            px: 3,
                            py: 0.75,
                            textTransform: "none",
                            fontWeight: 600,
                          }}
                        >
                          Get Started Free
                        </Button>
                      </motion.div>
                    </>
                  )}
                </Stack>
              )}

              {/* Mobile Menu Toggle */}
              {isMobile && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ThemeToggle />
                  <IconButton
                    color="inherit"
                    onClick={toggleMobileMenu}
                    sx={{ ml: 1, color: textColor }}
                  >
                    {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Box>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>

      {/* Account Menu Dropdown */}
      <Menu
        id="account-menu"
        open={Boolean(menuState && menuState.id === "account-menu")}
        anchorEl={menuState ? menuState.anchor : null}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1.5,
            borderRadius: "12px",
            minWidth: "180px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
            "& .MuiMenuItem-root": {
              px: 2,
              py: 1.5,
              borderRadius: "8px",
              mx: 0.5,
              my: 0.25,
              fontSize: "0.95rem",
            },
          },
        }}
      >
        {auth?.user && (
          <>
            <MenuItem
              component={Link}
              to="/dashboard"
              onClick={() => {
                trackInternalLink("/dashboard", "Dashboard", "account_menu")
                handleCloseMenu()
              }}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              component={Link}
              to="/settings/general"
              onClick={() => {
                trackInternalLink(
                  "/settings/general",
                  "Settings",
                  "account_menu"
                )
                handleCloseMenu()
              }}
            >
              Settings
            </MenuItem>
            <Divider sx={{ my: 1 }} />
            <MenuItem
              onClick={() => {
                // Track sign out event
                trackEvent("Authentication", "sign_out", "account_menu", null, {
                  auth_action: "sign_out",
                  auth_method: "account_menu",
                  auth_success: "true",
                })
                auth.signout()
                handleCloseMenu()
              }}
              sx={{
                color: muiTheme.palette.error.main,
              }}
            >
              Sign out
            </MenuItem>
          </>
        )}
      </Menu>

      {/* Mobile Menu */}
      {isMobile && (
        <Fade in={mobileMenuOpen}>
          <Box
            className={classes.mobileMenu}
            sx={{
              backgroundColor: isDark
                ? "rgba(18, 18, 18, 0.95)"
                : "rgba(255, 255, 255, 0.95)",
              color: textColor,
            }}
          >
            <IconButton
              color="inherit"
              onClick={toggleMobileMenu}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                color: textColor,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Stack spacing={2} sx={{ mb: 4 }}>
              {navLinks.map((link, index) => (
                <Button
                  key={index}
                  component={Link}
                  to={link.path}
                  color="inherit"
                  size="large"
                  onClick={() => {
                    trackInternalLink(link.path, link.title, "mobile_nav")
                    toggleMobileMenu()
                  }}
                  title={`${link.title} - Resume Revival`}
                  aria-label={`${link.title} - Resume Revival`}
                  sx={{
                    justifyContent: "flex-start",
                    fontSize: "1.25rem",
                    py: 1.5,
                    borderRadius: "8px",
                    textTransform: "none",
                    color: textColor,
                  }}
                >
                  {link.title}
                </Button>
              ))}
            </Stack>

            <Divider sx={{ mb: 3 }} />

            {auth?.user ? (
              <Stack spacing={2}>
                <Button
                  component={Link}
                  to="/dashboard"
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => {
                    trackInternalLink("/dashboard", "Dashboard", "mobile_nav")
                    toggleMobileMenu()
                  }}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Dashboard
                </Button>
                <Button
                  onClick={() => {
                    // Track sign out event
                    trackEvent(
                      "Authentication",
                      "sign_out",
                      "mobile_menu",
                      null,
                      {
                        auth_action: "sign_out",
                        auth_method: "mobile_menu",
                        auth_success: "true",
                      }
                    )
                    auth.signout()
                    toggleMobileMenu()
                  }}
                  variant="text"
                  color="inherit"
                  size="large"
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    color: textColor,
                  }}
                >
                  Sign out
                </Button>
              </Stack>
            ) : (
              <>
                {/* Primary Sign Up Button (Mobile) */}
                <Button
                  href={appRoutes.signIn} // Reverted back to signIn
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    py: 1.5,
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Get Started Free
                </Button>
                {/* Secondary Sign In Button (Mobile) */}
                <Button
                  href={appRoutes.signIn}
                  variant="text" // Changed from contained
                  color="inherit" // Changed from primary
                  size="large"
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    py: 1.5,
                    textTransform: "none",
                    fontWeight: 500, // Adjusted weight
                    color: textColor, // Ensure correct text color
                    mt: 1, // Add some margin
                  }}
                >
                  Sign in
                </Button>
              </>
            )}
          </Box>
        </Fade>
      )}
    </>
  )
}

export default Navbar
