// src/util/auth.js

import React, { createContext, useContext, useState } from "react"

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const signin = (userData, callback) => {
    setUser(userData)
    if (callback) callback()
  }

  const signout = callback => {
    setUser(null)
    if (callback) callback()
  }

  return (
    <AuthContext.Provider value={{ user, signin, signout }}>
      {children}
    </AuthContext.Provider>
  )
}
