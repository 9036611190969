import React from "react"
import {
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  useTheme as useMuiTheme,
  SvgIcon,
} from "@mui/material"
import { Link } from "gatsby"
import { makeStyles } from "@mui/styles"
import { motion } from "framer-motion"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import EmailIcon from "@mui/icons-material/Email"
import { useTheme } from "../util/themeProvider"
import { appRoutes } from "../util/appLinks"

// Custom X (formerly Twitter) icon
const XIcon = props => (
  <SvgIcon {...props}>
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </SvgIcon>
)

const useStyles = makeStyles(() => ({
  footer: {
    padding: "3rem 0",
    marginTop: "4rem",
    position: "relative",
  },
  logo: {
    height: 40,
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  link: {
    textDecoration: "none",
    position: "relative",
    padding: "0.2rem 0",
    margin: "0 1rem",
    fontWeight: 500,
    "&::after": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "2px",
      bottom: 0,
      left: 0,
      transition: "width 0.3s ease",
    },
    "&:hover::after": {
      width: "100%",
    },
  },
  socialIcon: {
    margin: "0 0.8rem",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "translateY(-2px) rotate(8deg)",
    },
  },
  section: {
    marginBottom: "2rem",
  },
  description: {
    maxWidth: "600px",
    margin: "1rem auto",
    lineHeight: 1.6,
  },
}))

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
      staggerChildren: 0.1,
    },
  },
}

const socialIconVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
}

const Footer = () => {
  const classes = useStyles()
  const { isDark } = useTheme()
  const muiTheme = useMuiTheme()

  // Get current colors based on theme
  const textColor = muiTheme.palette.text.primary
  const textSecondary = muiTheme.palette.text.secondary
  const accentColor = isDark
    ? muiTheme.palette.secondary.main
    : muiTheme.palette.primary.main
  const bgColor = isDark ? "#121212" : "#ffffff"
  const cardBgColor = isDark
    ? "rgba(30, 30, 30, 0.8)"
    : "rgba(255, 255, 255, 0.5)"
  const borderColor = isDark
    ? "rgba(255, 255, 255, 0.1)"
    : "rgba(63, 81, 181, 0.1)"
  const shadowColor = isDark ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.1)"
  const iconBgColor = isDark
    ? "rgba(255, 255, 255, 0.1)"
    : "rgba(63, 81, 181, 0.1)"
  const iconHoverBgColor = isDark
    ? "rgba(255, 255, 255, 0.15)"
    : "rgba(63, 81, 181, 0.15)"

  // Social media data with actual links
  const socialMedia = [
    {
      href: "https://www.linkedin.com/company/resumerevival-xyz/",
      label: "LinkedIn",
      icon: <LinkedInIcon fontSize="small" />,
    },
    {
      href: "https://x.com/Resume_Revival",
      label: "X",
      icon: <XIcon fontSize="small" />,
    },
    {
      href: "https://www.facebook.com/people/Resume-Revival/100091365447091/",
      label: "Facebook",
      icon: <FacebookIcon fontSize="small" />,
    },
    {
      href: "https://www.instagram.com/resumerevival.xyz/",
      label: "Instagram",
      icon: <InstagramIcon fontSize="small" />,
    },
  ]

  return (
    <Box
      sx={{
        bgcolor: bgColor,
        borderTop: `1px solid ${borderColor}`,
      }}
    >
      <Container maxWidth="lg" className={classes.footer}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box
              textAlign={{ xs: "center", md: "left" }}
              sx={{
                background: cardBgColor,
                borderRadius: "16px",
                padding: "24px",
                boxShadow: `0 4px 30px ${shadowColor}`,
                color: textColor,
              }}
            >
              {/* Logo link with visually-hidden text for SEO crawlers */}
              <Link to="/" aria-label="Resume Revival Home">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/resumerevival-bb193.appspot.com/o/logo.png?alt=media&token=9086b528-7c1d-43d0-98b5-1dffa979b644"
                  alt="Resume Revival Logo"
                  className={classes.logo}
                  width={40}
                  height={40}
                />
                <span className="visually-hidden">Resume Revival Home</span>
              </Link>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  mt: 2,
                  fontWeight: 600,
                  fontSize: "1.5rem",
                  color: textColor,
                }}
              >
                Resume Revival
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: textSecondary }}
                className={classes.description}
              >
                Empowering careers through AI-powered resume creation and
                optimization. Transform your professional journey with our
                cutting-edge tools and expert guidance.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              textAlign="center"
              className={classes.section}
              sx={{
                background: cardBgColor,
                borderRadius: "16px",
                padding: "24px",
                boxShadow: `0 4px 30px ${shadowColor}`,
                color: textColor,
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{ fontWeight: 600, fontSize: "1.25rem", color: textColor }}
              >
                Quick Links
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  alignItems: "center",
                }}
              >
                <Link
                  to="/features"
                  className={classes.link}
                  style={{
                    color: textColor,
                  }}
                  sx={{
                    "&::after": {
                      backgroundColor: accentColor,
                    },
                  }}
                >
                  Features
                </Link>
                <Link
                  to={appRoutes.pricing}
                  className={classes.link}
                  style={{
                    color: textColor,
                  }}
                  sx={{
                    "&::after": {
                      backgroundColor: accentColor,
                    },
                  }}
                >
                  Pricing
                </Link>
                <Link
                  to="/about"
                  className={classes.link}
                  style={{
                    color: textColor,
                  }}
                  sx={{
                    "&::after": {
                      backgroundColor: accentColor,
                    },
                  }}
                >
                  About Us
                </Link>
                <Link
                  to="/contact/"
                  className={classes.link}
                  style={{
                    color: textColor,
                  }}
                  sx={{
                    "&::after": {
                      backgroundColor: accentColor,
                    },
                  }}
                >
                  Contact
                </Link>
                <Link
                  to="/privacy-policy"
                  className={classes.link}
                  style={{
                    color: textColor,
                  }}
                  sx={{
                    "&::after": {
                      backgroundColor: accentColor,
                    },
                  }}
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/terms-of-service"
                  className={classes.link}
                  style={{
                    color: textColor,
                  }}
                  sx={{
                    "&::after": {
                      backgroundColor: accentColor,
                    },
                  }}
                >
                  Terms of Service
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              textAlign={{ xs: "center", md: "right" }}
              sx={{
                background: cardBgColor,
                borderRadius: "16px",
                padding: "24px",
                boxShadow: `0 4px 30px ${shadowColor}`,
                color: textColor,
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{ fontWeight: 600, fontSize: "1.25rem", color: textColor }}
              >
                Connect With Us
              </Typography>
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                {socialMedia.map((social, index) => (
                  <IconButton
                    key={index}
                    className={classes.socialIcon}
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Resume Revival on ${social.label}`}
                    title={`Follow Resume Revival on ${social.label}`}
                    sx={{
                      color: textColor,
                      background: iconBgColor,
                      width: 40,
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        background: iconHoverBgColor,
                      },
                    }}
                  >
                    {social.icon}
                    <span className="visually-hidden">
                      Follow Resume Revival on {social.label}
                    </span>
                  </IconButton>
                ))}
                {/* Contact button with visually-hidden text for SEO crawlers */}
                <IconButton
                  className={classes.socialIcon}
                  component={Link}
                  to="/contact/"
                  aria-label="Contact Resume Revival"
                  title="Contact Resume Revival"
                  sx={{
                    color: textColor,
                    background: iconBgColor,
                    width: 40,
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      background: iconHoverBgColor,
                    },
                  }}
                >
                  <EmailIcon fontSize="small" />
                  <span className="visually-hidden">
                    Contact Resume Revival
                  </span>
                </IconButton>
              </Box>
              <Typography variant="body2" sx={{ color: textSecondary }}>
                Contact us through our{" "}
                <Link to="/contact/" style={{ color: textSecondary }}>
                  contact page
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: 4,
            pt: 3,
            textAlign: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: textSecondary }}
          >
            © {new Date().getFullYear()} Resume Revival. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
