// gatsby-browser.js
import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "./src/util/themeProvider"
import Layout from "./src/components/layout"
import "./src/util/security" // Import security utilities
// Storage utilities are now using standardized navigator.storage API
// and are available for import when needed
import "./src/styles/global.css" // Import global CSS for rendering quality
// Import Prism.js theme for syntax highlighting
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "./src/styles/prism-custom.css" // Import custom Prism.js styling
import {
  initGA,
  trackPageView,
  trackScrollDepth,
  trackTimeOnPage,
} from "./src/util/analytics"

// Initialize Google Analytics with enhanced configuration
if (typeof window !== "undefined") {
  // Set up custom dimensions for better user segmentation
  const customDimensions = {
    dimension1: window.innerWidth <= 768 ? "mobile" : "desktop", // Device type
    dimension2: document.referrer ? "referred" : "direct", // Traffic source type
    dimension3:
      new Date().getHours() < 12
        ? "morning"
        : new Date().getHours() < 18
        ? "afternoon"
        : "evening", // Time of day
    dimension4:
      new Date().getDay() === 0 || new Date().getDay() === 6
        ? "weekend"
        : "weekday", // Day type
  }

  initGA("G-4P1JC0LK9T", {
    debug: process.env.NODE_ENV === "development",
    customDimensions: customDimensions,
  })
}

// Expose ReactDOM for custom component rendering
if (typeof window !== "undefined") {
  window.ReactDOM = ReactDOM
}

// Performance optimization and enhanced analytics tracking
export const onRouteUpdate = ({ location, prevLocation }) => {
  // Track page view with enhanced Google Analytics
  if (typeof window !== "undefined") {
    // Capture additional metadata for the page view
    const metadata = {
      previous_page: prevLocation ? prevLocation.pathname : null,
      page_title: document.title,
      page_url: location.href,
      page_path: location.pathname,
      page_search: location.search,
      page_hash: location.hash,
      referrer: document.referrer,
      viewport_width: window.innerWidth,
      viewport_height: window.innerHeight,
      timestamp: new Date().toISOString(),
    }

    // Send enhanced pageview to Google Analytics
    trackPageView(location.pathname, document.title, metadata)

    // Set up scroll depth tracking
    let scrollDepthTracked = {
      25: false,
      50: false,
      75: false,
      100: false,
    }

    const handleScroll = () => {
      const scrollTop = window.scrollY
      const docHeight =
        document.documentElement.scrollHeight - window.innerHeight
      const scrollPercent = (scrollTop / docHeight) * 100

      // Track scroll depth at 25%, 50%, 75%, and 100%
      if (scrollPercent >= 25 && !scrollDepthTracked[25]) {
        trackScrollDepth(25, document.title)
        scrollDepthTracked[25] = true
      }
      if (scrollPercent >= 50 && !scrollDepthTracked[50]) {
        trackScrollDepth(50, document.title)
        scrollDepthTracked[50] = true
      }
      if (scrollPercent >= 75 && !scrollDepthTracked[75]) {
        trackScrollDepth(75, document.title)
        scrollDepthTracked[75] = true
      }
      if (scrollPercent >= 100 && !scrollDepthTracked[100]) {
        trackScrollDepth(100, document.title)
        scrollDepthTracked[100] = true
      }
    }

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll, { passive: true })

    // Track time on page when user leaves
    let startTime = Date.now()
    const trackTimeBeforeLeaving = () => {
      const timeSpent = Math.round((Date.now() - startTime) / 1000)
      if (timeSpent > 5) {
        // Only track if spent more than 5 seconds
        trackTimeOnPage(timeSpent, document.title)
      }
    }

    // Add event listeners for when user leaves the page
    window.addEventListener("beforeunload", trackTimeBeforeLeaving)

    // Clean up event listeners when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("beforeunload", trackTimeBeforeLeaving)
      trackTimeBeforeLeaving() // Track time when navigating between pages
    }

    setTimeout(() => {
      // Preload images for the next page
      const preloadLink = document.createElement("link")
      preloadLink.rel = "preload"
      preloadLink.as = "image"
      preloadLink.href = "/favicon.png"
      document.head.appendChild(preloadLink)

      // Fix for table of contents navigation
      const fixTableOfContents = () => {
        // First ensure all headings have IDs
        const headings = document.querySelectorAll("h1, h2, h3")
        headings.forEach((heading, index) => {
          if (!heading.id) {
            // Create an ID from the heading text
            const headingText = heading.textContent
              .trim()
              .toLowerCase()
              .replace(/[^\w\s-]/g, "") // Remove special chars
              .replace(/\s+/g, "-") // Replace spaces with hyphens
              .replace(/-+/g, "-") // Replace multiple hyphens with single hyphen

            // Add a unique index to avoid duplicate IDs
            heading.id = `heading-${headingText}-${index}`
            console.log(`Added ID to heading: ${heading.id}`)
          }
        })

        // Target the desktop TOC links
        const tocLinks = document.querySelectorAll(".table-of-contents a")
        console.log(`Found ${tocLinks.length} TOC links`)

        if (tocLinks.length > 0) {
          tocLinks.forEach(link => {
            // Log the link href for debugging
            console.log(
              `TOC link: ${link.getAttribute("href")} -> ${link.textContent}`
            )

            // Remove existing event listeners by cloning
            const newLink = link.cloneNode(true)
            if (link.parentNode) {
              link.parentNode.replaceChild(newLink, link)
            }

            // Add new click handler
            newLink.addEventListener("click", function (e) {
              e.preventDefault()
              const targetId = this.getAttribute("href")?.substring(1)
              console.log(`Clicked TOC link to: #${targetId}`)

              if (targetId) {
                const targetElement = document.getElementById(targetId)
                if (targetElement) {
                  console.log(`Found target element: ${targetElement.tagName}`)
                  // Scroll to element with offset for header
                  const yOffset = -80
                  const y =
                    targetElement.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset

                  window.scrollTo({
                    top: y,
                    behavior: "auto",
                  })

                  // Update URL without scrolling
                  if (window.history) {
                    window.history.pushState(null, "", `#${targetId}`)
                  }
                } else {
                  console.log(`Target element not found: #${targetId}`)
                }
              }
            })
          })

          console.log("TOC navigation fixed")
        }
      }

      // Run the fix immediately and again after a short delay to ensure it catches any dynamically loaded content
      fixTableOfContents()
      setTimeout(fixTableOfContents, 1000)
    }, 0)
  }

  // Implement intersection observer for lazy loading images
  if (typeof window !== "undefined" && "IntersectionObserver" in window) {
    const lazyImages = document.querySelectorAll("img[data-src]")

    const imageObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target
          img.src = img.dataset.src
          img.removeAttribute("data-src")
          imageObserver.unobserve(img)
        }
      })
    })

    lazyImages.forEach(img => imageObserver.observe(img))
  }

  return null
}

// Add support for prefetching
export const onPrefetchPathname = ({ pathname }) => {
  // You can add custom prefetching logic here
  console.log(`Prefetching: ${pathname}`)
}

// Apply initial theme based on localStorage and optimize initial load
// This now matches the approach in ThemeProvider to avoid hydration mismatches
export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Disable non-critical animations during page load
    const enableAnimations = () => {
      document.body.classList.remove("preload")
      window.removeEventListener("load", enableAnimations)
    }
    document.body.classList.add("preload")
    window.addEventListener("load", enableAnimations)

    // Add CSS to disable animations during page load
    const style = document.createElement("style")
    style.innerHTML = `
    .preload * {
      animation-duration: 0s !important;
      transition-duration: 0s !important;
    }
    `
    document.head.appendChild(style)

    // Add support for native lazy loading of images
    if ("loading" in HTMLImageElement.prototype) {
      setTimeout(() => {
        const images = document.querySelectorAll('img[loading="lazy"]')
        images.forEach(img => {
          if (img.dataset.src) {
            img.src = img.dataset.src
          }
        })
      }, 0)
    }
  }
}

// Wrap the entire site in the theme
export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

// Wrap every page in <Layout>, so Navbar/Footer show up everywhere
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
