// src/components/ThemeToggle.js

import React from "react"
import { useTheme } from "../util/themeProvider"
import IconButton from "@mui/material/IconButton"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import Tooltip from "@mui/material/Tooltip"

/**
 * A simple button component that toggles between light and dark mode
 */
const ThemeToggle = () => {
  const { isDark, toggleTheme } = useTheme()

  const handleToggle = () => {
    // Call the theme toggle function
    toggleTheme()
  }

  return (
    <Tooltip title={isDark ? "Switch to light mode" : "Switch to dark mode"}>
      <IconButton
        onClick={handleToggle}
        color="inherit"
        aria-label="toggle theme"
        sx={{ ml: 1 }}
        data-theme-mode={isDark ? "dark" : "light"}
      >
        {isDark ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  )
}

export default ThemeToggle
